import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Card from '../components/card';

import Pdf from '../assets/svg/pdf.svg';
import Map from '../assets/svg/map.svg';
import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${layoutStyle}
  @media (min-width: ${media.bigDesktop}px) {
    max-width: 90%;
  }

  h1 {
    padding-bottom: 2rem;
    font-size: 3rem;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;

    @media (min-width: ${media.phoneLandscape}px) {
      flex-direction: row;
    }

    ${Card} {
      z-index: 1;
      flex-direction: column;
      margin-top: -1rem;
      p {
        margin-bottom: 0.5rem;
      }

      @media (min-width: ${media.phoneLandscape}px) {
        flex: 1 0 55%;
        margin-top: 0;
      }
    }

    .gatsby-image-wrapper {
      min-height: 16.25rem;
      width: 95%;
      border-radius: 0.25rem;

      @media (min-width: ${media.phoneLandscape}px) {
        flex: 0 1 45%;
        margin-left: -1rem;
        max-width: 60%;
      }
    }

    &:last-child {
      margin-top: 2rem;

      flex-direction: column-reverse;

      @media (min-width: ${media.phoneLandscape}px) {
        flex-direction: row-reverse;
      }

      .gatsby-image-wrapper {
        @media (min-width: ${media.phoneLandscape}px) {
          margin-left: 0;
          margin-right: -1rem;
        }
      }
    }
  }
`;

const ContactContainer = styled.div`
  background-color: ${colors.background};

  > div {
    display: flex;
    ${layoutStyle}

    flex-direction: column;

    @media (min-width: ${media.tabletPortrait}px) {
      flex-direction: row;
    }
  }
`;

const Contacts = styled.address`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;

  @media (min-width: ${media.tabletPortrait}px) {
    padding-top: 0;
    padding-left: 3rem;
  }

  h3 {
    font-size: 2rem;
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2rem;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;

    color: ${colors.blue};
    transition: color 150ms ease-in-out;

    &:focus,
    &:hover {
      color: ${colors.lightBlue};
    }

    svg {
      min-width: 2rem;
      min-height: 3rem;
      margin-right: 1rem;
    }

    p,
    span {
      font-size: 1.25rem;
    }
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 10rem;
`;

const BackgroundSponsorsContainer = styled.div`
  > div {
    display: flex;
    flex-direction: column;

    > h2 {
      padding-bottom: 3rem;
      text-align: center;
      font-size: 2rem;
      font-weight: 500;
    }

    ${layoutStyle}
  }
`;

const SponsorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const Sponsor = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  flex: 1 0 100%;
  margin: 0 auto;

  &:not(:last-child) {
    padding-bottom: 3rem;
  }

  @media (min-width: ${media.phoneLandscape}px) {
    flex: 0 1 50%;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    flex: 1 0 33%;

    &:not(:last-child) {
      padding-bottom: 0;
    }
  }

  svg {
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
  }

  > div {
    padding-right: 1rem;
    display: flex;
  }
`;

function AboutPage({ location: { pathname } }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          mail
          gmaps
        }
      }
      uptec: file(relativePath: { eq: "UPTEC.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rhaq: allFile(filter: { base: { eq: "rhaq-azitek.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <Layout padTop>
      <SEO title="About" pathname={pathname} />
      <AboutContainer>
        <h1>We Are Azitek</h1>
        <div>
          <Card>
            <h2>Azitek</h2>
            <p>
              {
                'Azitek is a Porto University spin-off. We are currently located at UPTEC, which is an innovation hub that stimulates the growth of startups and technological advancements.'
              }
            </p>
            <p>
              {
                'We develop our solution from the hardware to the cloud, since the technology was created from scratch, which allows us to have a variety of clients in many different sectors, as the adaptation is simple. The technology provided by Azitek helps companies optimise their operations in supply chains and increase their production efficiency, to improve asset management.'
              }
            </p>
            <p>
              {
                'Azitek invests constantly in innovation and development of solutions that meet the needs of their clients and of an ever-changing market. Real-time asset monitorisation and digitalisation of logistics processes is the path to follow and the creation of new solutions that had not yet been exploited by industry 4.0 is a goal that we are reaching step by step.'
              }
            </p>
          </Card>
          <Img fluid={data.uptec.childImageSharp.fluid} />
        </div>
      </AboutContainer>
      <ContactContainer>
        <div>
          <Contacts>
            <h3>Reach Us</h3>
            <a
              href="https://www.openstreetmap.org/export/embed.html?bbox=-8.604404,41.176813,-8.604404,41.176813&layer=mapnik&marker=41.176813,%20-8.604404"
              aria-label="Our Office"
            >
              <Map />
              <Address>
                <p>UPTEC</p>
                <p>Rua Alfredo Allen, N.º455 3.16, 4200-135 </p>
                <p>Porto, Portugal</p>
              </Address>
            </a>
          </Contacts>
        </div>
      </ContactContainer>
      <BackgroundSponsorsContainer>
        <div>
          <h2>Co-financed Projects</h2>
          <SponsorsContainer>
            <Sponsor>
              <Pdf />
              <div>
                <h2>
                  <a href={data.rhaq.edges[0].node.publicURL} aria-label="RHAQ">
                    RHAQ
                  </a>
                </h2>
              </div>
            </Sponsor>
          </SponsorsContainer>
        </div>
      </BackgroundSponsorsContainer>
    </Layout>
  );
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default AboutPage;
