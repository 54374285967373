import styled from 'styled-components';

import { colors } from '../styles/constants';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.35);
  transition: 0.5s;
  border: 1px solid ${colors.grey};
  border-radius: 0.25rem;
  background-color: ${colors.lightGrey};
  padding: 2rem;

  h2 {
    padding-bottom: 1rem;
  }
`;

export default Card;
